<template>
  <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
    <div
      class="d-flex flex-column flex-grow-1 grey lighten-4 pa-2"
      style="border-radius: 3px;"
      v-if="recordedLubeSales.length > 0"
    >
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><div
              class="d-flex flex-row flex-grow-1"
              style="justify-content: space-between;"
            >
              <span
                class=" font font-size-md  mt-2 text-uppercase font-weight-medium"
                style="border-radius: 3px;"
                >Recorded Lube Sales</span
              >
              <v-chip color="primary" small label class="mt-1 mr-2">
                <span class="font font-weight-medium font-size-md">
                  {{
                    recordedLubeSales.reduce(
                      (acc, sale) => (acc += parseInt(sale.amount)),
                      0
                    )
                      | currencyFormat(
                        currency && currency.length > 0
                          ? currency[0].code
                          : "GHS"
                      )
                  }}</span
                >
              </v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-for="(lubeSale, index) in recordedLubeSales"
              :key="index"
              class="d-flex flex-column flex-grow-1 my-1 white pa-2 ma-2 box-shadow-light"
            >
              <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1">
                <div class="d-flex flex-grow-1">
                  <v-text-field
                    readonly
                    label="Lube Name"
                    v-model="lubeSale.productId.name"
                    prepend-icon="badge"
                    class="font font-weight-medium font-size-md"
                    dense
                  />
                </div>
                <div class="d-flex flex-grow-1">
                  <v-text-field
                    label="Quantity Sold"
                    readonly
                    v-model="lubeSale.quantitySold"
                    prepend-icon="shopping_basket"
                    class="font font-weight-medium font-size-md"
                    dense
                  />
                </div>
                <div class="d-flex flex-grow-1">
                  <v-text-field
                    readonly
                    label="Total Amount"
                    prepend-icon="credit_card"
                    v-model="lubeSale.amount"
                    :prefix="
                      currency && currency.length > 0
                        ? currency[0].symbol
                        : 'GHS'
                    "
                    class="font font-weight-medium font-size-md"
                    dense
                  />
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
      style="align-items:center;"
    >
      <img src="@/assets/expenses.svg" style="width: 80px;" />
      <span class="font font-weight-medium"
        >No lube sales have been recorded yet on this date
        <kbd class="mr-2">{{ date }}</kbd
        >.</span
      >
      <span class="font font-size-sm font-weight-medium">
        Lube sales should be recorded before fuel sales.
      </span>
    </div>
  </div>
</template>
<script>
import { currencyFormat } from "../../../filters/currencyFormat";
export default {
  name: "RecordedLubes",
  props: {
    recordedLubeSales: { type: Array, default: () => [] },
    currency: { type: Array, default: () => [] },
    date: {
      type: String,
      default: null
    }
  },
  filters: {
    currencyFormat
  }
};
</script>
